.nav {
	&__open-btn {
		@include media('<=md') {
			display: block;
		}
		@include media('<sm') {
			margin: 10px 0 0 12px;
		}

		position: relative;

		display: none;

		width: 30px;
		height: 30px;
		margin: 20px 0 0 28px;
		padding: 0;

		cursor: pointer;

		border: 0;
		outline: none;
		background: none;

		&::before,
		&::after,
		& > i {
			position: absolute;
			left: calc(50% - 12px);

			display: block;

			width: 24px;
			height: 2px;

			content: '';
			transition: background $t;

			background: $spicymix;
		}

		&::before {
			top: 6px;
		}

		&::after {
			top: 22px;
		}

		& > i {
			top: 14px;
		}

		body:not(.mobile) &:hover {
			&::before,
			&::after,
			& > i {
				background: $teak;
			}
		}
	}

	&__menu {
		@include media('<=md') {
			position: fixed;
			z-index: 100;
			top: 0;
			left: 0;

			overflow-y: auto;

			width: 300px;
			height: 100%;
			padding: 70px 0 0;

			transition: opacity .1s ease .4s, transform .5s ease;
			transform: translate3d(-100%,0,0);

			opacity: 0;
			background: rgba(white,.9);
		}
		@include media('<sm') {
			padding: 50px 0 0;
		}

		position: relative;
		z-index: 2;

		.nav._open & {
			@include media('<=md') {
				transition: opacity .1s ease, transform .5s ease;
				transform: none;

				opacity: 1;
			}
		}
	}

	&__close-btn {
		@include media('<=md') {
			display: block;
		}
		@include media('<sm') {
			top: 10px;
			left: 12px;
		}

		position: absolute;
		top: 20px;
		left: 27px;

		display: none;

		width: 30px;
		height: 30px;

		cursor: pointer;

		border: 0;
		outline: none;
		background: none;

		&::before,
		&::after {
			position: absolute;
			top: calc(50% - 1px);
			left: calc(50% - 12px);

			display: block;

			width: 24px;
			height: 2px;

			content: '';

			background: $spicymix;
		}

		&::before {
			transform: rotate(45deg);
		}

		&::after {
			transform: rotate(-45deg);
		}
	}

	&__list {
		@include reset-list;
		@include media('<=md') {
			display: block;

			padding: 0;
		}

		display: flex;

		padding: 0 24px;
	}

	&__item {
		@include media('<=md') {
			display: flex;
			flex-direction: column;

			flex-wrap: wrap;

			margin: 0;
		}

		position: relative;

		margin: 0 6px;

		&:nth-child(n+2) {
			@include media('<=md') {
				border-top: 1px solid $teak;
			}
		}
	}

	&__link {
		@include media('<=md') {
			width: 100%;
			padding: 10px 30px;
		}
		@include media('<sm') {
			padding: 10px 15px;
		}

		font-weight: bold;

		display: block;

		padding: 25px 0;

		text-decoration: none;

		color: black;

		.nav__submenu + &::after {
			display: inline-block;

			width: 12px;
			height: 8px;
			margin-left: 5px;

			content: '';
			transition: opacity $t;

			opacity: .75;
			background: url('data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'12\' height=\'8\' viewBox=\'0 0 12 8\'%3E%3Cpolygon fill=\'%23b59068\' points=\'12,1.6 7.2,6.4 7.2,6.4 6,7.6 0,1.6 1.2,0.4 6,5.2 10.8,0.4 \'/%3E%3C/svg%3E%0A') no-repeat 50% 50% / contain;
		}

		body:not(.mobile) &:hover,
		.nav__item._open & {
			color: $teak;

			&::after {
				opacity: 1;
			}
		}

		.nav__item._open &::after {
			@include media('<=md') {
				transform: rotate(180deg);
			}
		}
	}

	&__submenu {
		@include reset-list;
		@include media('<=md') {
			font-size: 12px;
			line-height: 1.25;

			position: static;
			top: auto;
			left: auto;

			display: none;
			visibility: visible;

			width: 100%;
			padding: 0 30px 10px 40px;

			opacity: 1;
			background: none;

			order: 1;
		}
		@include media('<sm') {
			padding: 0 15px 10px 25px;
		}

		position: absolute;
		top: 100%;
		left: -16px;

		visibility: hidden;

		width: 308px;

		transition: opacity $t, visibility $t;

		opacity: 0;
		background: rgba(white,.9);

		.nav__item._open & {
			@include media('<=md') {
				display: block;
			}

			visibility: visible;

			opacity: 1;
		}
	}

	&__submenu-item {
		&:nth-child(n+2) {
			@include media('>md') {
				border-top: 1px solid $teak;
			}
			@include media('<=md') {
				@include margin-top(8px);
			}
		}
	}

	&__submenu-link {
		@include media('<=md') {
			display: inline;

			padding: 0;
		}

		display: block;

		padding: 5px 16px;

		text-decoration: none;

		color: black;

		body:not(.mobile) &:hover {
			color: $teak;
		}
	}
}

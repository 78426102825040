@font-face {
	font-family: 'PFAgoraSlabPro';
	font-weight: 400;
	font-style: normal;

	src: url('../fonts/pfagoraslabpro-reg.woff2') format('woff2'),
	url('../fonts/pfagoraslabpro-reg.woff') format('woff');
}

@font-face {
	font-family: 'PFAgoraSlabPro';
	font-weight: 400;
	font-style: italic;

	src: url('../fonts/pfagoraslabpro-italic.woff2') format('woff2'),
	url('../fonts/pfagoraslabpro-italic.woff') format('woff');
}

@font-face {
	font-family: 'PFAgoraSlabPro';
	font-weight: 500;
	font-style: normal;

	src: url('../fonts/pfagoraslabpro-medium.woff2') format('woff2'),
	url('../fonts/pfagoraslabpro-medium.woff') format('woff');
}

@font-face {
	font-family: 'PFAgoraSlabPro';
	font-weight: 700;
	font-style: normal;

	src: url('../fonts/pfagoraslabpro-bold.woff2') format('woff2'),
	url('../fonts/pfagoraslabpro-bold.woff') format('woff');
}

@font-face {
	font-family: 'PFAgoraSlabPro';
	font-weight: 700;
	font-style: italic;

	src: url('../fonts/pfagoraslabpro-bolditalic.woff2') format('woff2'),
	url('../fonts/pfagoraslabpro-bolditalic.woff') format('woff');
}

.tel {
	@include margin-bottom(1em);
	@include media('<md') {
		font-size: 32px;
	}
	@include media('<xs') {
		font-size: 24px;
	}

	font-size: 36px;
	font-weight: bold;
	line-height: 1;

	&__link {
		text-decoration: none;

		color: white;
	}

	&__icon {
		@include media('<xs') {
			width: 18px;
			height: 18px;
		}

		display: inline-block;

		fill: white;
	}
}

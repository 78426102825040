.popup {
	font-size: 0;

	position: fixed;
	z-index: 100;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	display: none;
	overflow-y: auto;

	transition: opacity .5s ease;

	opacity: 0;

	&::before {
		display: inline-block;

		height: 100%;

		content: '';
		vertical-align: middle;
	}

	&._active {
		display: block;
	}

	&._visible {
		opacity: 1;
	}

	&__inner {
		display: inline-block;

		width: 100%;
		padding: 10px;

		vertical-align: middle;
	}

	&__body {
		font-size: 1rem;

		max-width: 540px;
		margin: 0 auto;
		padding: 28px;

		border: 2px solid $teak;
		background: white;
	}

	&__close-button {
		position: relative;

		float: right;

		width: 30px;
		height: 30px;
		margin: -8px -8px 0 20px;
		padding: 0;

		cursor: pointer;

		border: 0;
		outline: none;
		background: none;

		&::before,
		&::after {
			position: absolute;
			top: calc(50% - 1px);
			left: calc(50% - 8px);

			display: block;

			width: 16px;
			height: 16px;
			height: 2px;

			content: '';

			background: black;
		}

		&::before {
			transform: rotate(45deg);
		}

		&::after {
			transform: rotate(-45deg);
		}
	}
}

.content {
	@include media('<sm') {
		padding: 20px 15px 0;
	}

	display: flex;

	max-width: 1240px;
	margin: 0 auto 40px;
	padding: 20px 30px 0;

	flex-wrap: wrap;
	justify-content: space-between;

	&__main {
		@include media('<sm') {
			width: 100%;

			order: 0;
		}

		width: calc(100% - 270px);
		max-width: 880px;

		order: 1;
	}

	&__aside {
		@include media('<sm') {
			width: 100%;
			margin-top: 40px;
			padding-top: 0;
		}

		width: 240px;
		padding-top: 20px;
	}

	&__aside-block {
		@include margin-top(30px);
	}

	&__aside-caption {
		font-weight: bold;

		margin-bottom: 20px;

		letter-spacing: .05em;
	}
}

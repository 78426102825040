.location {
	@include media('<sm') {
		max-width: calc(100% - 43px);
		min-height: 50px;
		padding: 10px 15px;
	}
	@include media('<xs') {
		font-size: 11px;
	}

	position: relative;

	display: flex;

	min-height: 70px;
	padding: 20px 30px;

	align-items: center;
	justify-content: space-between;

	&::before {
		display: block;

		width: 24px;
		height: 24px;

		content: '';

		background: url('data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'32\' height=\'32\' viewBox=\'0 0 32 32\'%3E%3Cpath fill=\'%23000000\' d=\'M16 0c-8.837 0-16 7.163-16 16s7.163 16 16 16 16-7.163 16-16-7.163-16-16-16zM3 16c0-7.18 5.82-13 13-13 3.424 0 6.538 1.325 8.86 3.488l-12.86 5.512-5.512 12.86c-2.164-2.322-3.488-5.436-3.488-8.86zM18.286 18.286l-8.003 3.43 3.43-8.003 4.573 4.573zM16 29c-3.424 0-6.539-1.325-8.86-3.488l12.86-5.512 5.512-12.86c2.164 2.322 3.488 5.436 3.488 8.86 0 7.18-5.82 13-13 13z\'%3E%3C/path%3E%3C/svg%3E') no-repeat 50% 50% / 24px 24px;
	}

	&::after {
		@include media('<=md') {
			display: none;
		}

		position: absolute;
		top: calc(50% - 22px);
		left: 0;

		display: block;

		width: 1px;
		height: 44px;

		content: '';

		background: rgba(#ebccab, .9);
	}

	&__adr {
		width: calc(100% - 32px);

		white-space: nowrap;
	}
}

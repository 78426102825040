.partners {
	position: relative;

	padding: 0 30px;

	&__item {
		padding: 1px 5px;
	}

	&__image {
		display: block;

		max-width: 100%;
		height: auto;
		margin: 0 auto;

		box-shadow: 1px 1px 0 $mercury, -1px -1px 0 $mercury, 1px -1px 0 $mercury, -1px 1px 0 $mercury;
	}

	&__arrow {
		position: absolute;
		top: calc(50% - 15px);

		width: 30px;
		height: 30px;
		padding: 0;

		cursor: pointer;

		border: 0;
		outline: none;
		background: none;

		&::before {
			@include triangle(24px 14px, black, left);

			position: absolute;
			top: calc(50% - 12px);
			left: calc(50% - 7px);

			content: '';
			transition: border-color $t;
		}

		&._type_prev {
			left: 0;
		}

		&._type_next {
			right: 0;

			&::before {
				transform: rotate(180deg);
			}
		}

		&._disabled::before {
			border-right-color: $serenade;
		}
	}
}

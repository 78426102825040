*,
:before,
:after {
	box-sizing: border-box;
}

html {
	font-family: $pfagoraslabpro;
	font-size: 14px;
	line-height: 1.4286;

	display: flex;

	min-height: 100%;

	color: black;
	background: white;

	&.fixed {
		overflow-y: hidden;

		height: 100%;
	}

	&.has-scrollbar {
		overflow-y: scroll;
	}
}

body {
	display: flex;
	flex-direction: column;

	width: 100%;
	min-width: 320px;

	align-items: center;

	&.mobile {
		cursor: pointer;
	}

	html.fixed & {
		overflow: hidden;
	}
}

a {
	transition: color $t;

	color: $yellowmetal;

	body:not(.mobile) &:hover {
		text-decoration: none;
	}
}

h1 {
	@include media('<sm') {
		font-size: 24px;
		line-height: 1.25;

		margin-bottom: 15px;
	}

	font-size: 30px;
	font-weight: bold;
	line-height: 1;

	margin: 0 0 25px;

	letter-spacing: .025em;
}

h2 {
	@include margin-bottom(20px);
	@include media('<sm') {
		font-size: 18px;
	}

	font-size: 20px;
	font-weight: bold;
	line-height: 1.2;

	margin: 0;

	color: $emperor;
}

blockquote {
	@include margin-bottom(25px);
	@include media('<md') {
		font-size: 20px;
	}
	@include media('<sm') {
		font-size: 16px;

		padding: 3px 0 3px 15px;

		border-left-width: 5px;
	}

	font-size: 24px;
	line-height: 1.25;

	margin: 0;
	padding: 6px 0 6px 28px;

	border-left: 9px solid $emperor;
}

p {
	@include margin-bottom(1.4286em);

	margin: 0;
}

.text-center {
	text-align: center;
}

.text-left {
	text-align: left;
}

.text-right {
	text-align: right;
}

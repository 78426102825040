.page {
	display: flex;
	overflow: hidden;
	flex-direction: column;

	width: 100%;
	margin: 0 auto;

	flex-grow: 1;
	justify-content: space-between;

	&__inner {
		width: 100%;
	}
}

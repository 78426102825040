.btn {
	font-size: 16px;
	font-weight: bold;
	line-height: 1.25;

	display: inline-block;

	padding: 10px 20px;

	cursor: pointer;
	transition: background $t;
	text-transform: uppercase;

	color: white;
	border: 0;
	outline: none;
	background: $spicymix;

	body:not(.mobile) &:hover {
		background: $teak;
	}
}

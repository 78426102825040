.entry {
	font-size: 14px;
	line-height: 1.4286;

	display: block;

	width: 100%;
	padding: 5px 9px;

	transition: border-color $t, box-shadow $t;

	color: black;
	color: black;
	border: 1px solid $silverchalice;
	border-radius: 0;
	outline: none;
	background: white;
	box-shadow: none;

	appearance: none;

	&::placeholder {
		opacity: 1;
		color: $tundora;
	}

	&._textarea {
		height: 72px;

		resize: none;
	}

	&:focus {
		border-color: $teak;
		box-shadow: 1px 1px 0 $teak inset, -1px -1px 0 $teak inset;
	}
}

.header {
	background: url(../images/header.jpg) no-repeat 50% 0 / cover;

	&._index {
		@include media('<lg') {
			min-height: 84.5161vw;
		}
		@include media('<sm') {
			margin-bottom: 50px;
			padding-bottom: 20px;
		}

		min-height: 1048px;
		margin-bottom: 90px;
		padding-bottom: 30px;
	}

	&__top {
		@include media('<=md') {
			display: block;
		}

		display: flex;

		justify-content: flex-end;

		max-width: 1240px;
		margin: 0 auto;
	}

	&__top-inner {
		@include media('<=md') {
			justify-content: space-between;
		}

		display: flex;

		background: rgba(white,.9);
	}

	&__middle {
		background: rgba(#61441c, .38);
	}

	&__middle-inner {
		@include media('<sm') {
			display: block;

			padding: 20px 15px;
		}

		display: flex;
		justify-content: space-between;

		align-items: flex-end;

		max-width: 1240px;
		margin: 0 auto;
		padding: 30px;
	}

	&__about {
		@include media('<sm') {
			width: auto;
		}

		width: 300px;
		padding-bottom: 20px;

		color: white;
	}

	&__title {
		@include media('<sm') {
			font-size: 32px;
			line-height: 1.25;
		}
		@include media('<xs') {
			font-size: 24px;
		}

		font-size: 48px;
		font-weight: bold;
		line-height: 1;

		display: block;
		text-decoration: none;
		color: inherit;

		span {
			@include media('<sm') {
				font-size: 16px;
				line-height: 1.5;

				letter-spacing: 0;
			}

			font-size: 24px;
			line-height: 2;

			display: block;

			letter-spacing: .025em;
		}
	}

	&__subtitle {
		@include media('<sm') {
			margin-top: 0;
		}

		font-weight: 500;

		margin-top: 5px;
	}

	&__contacts {
		@include media('<sm') {
			width: auto;
			max-width: none;
			padding-left: 0;
		}

		width: calc(100% - 300px);
		max-width: 805px;
		padding-left: 30px;
	}

	&__bottom {
		@include media('<sm') {
			margin-top: 20px;
			padding: 0 15px;
		}

		max-width: 1240px;
		margin: 50px auto 0;
		padding: 0 30px;
	}
}

.navbox {
	@include margin-bottom(30px);

	&__caption {
		margin-bottom: 20px;
	}

	&__caption-link {
		position: relative;

		display: inline-block;

		margin-left: 14px;
		padding: 3px 14px 3px 12px;

		color: black;
		background: $serenade;

		&::before {
			@include triangle(26px 14px, $serenade, left);

			position: absolute;
			top: 0;
			right: 100%;

			display: block;

			content: '';
		}
	}

	&__list {
		@include reset-list;
	}

	&__item {
		@include margin-top(6px);
	}

	&__link {
		color: $emperor;
	}
}

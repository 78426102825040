.form {
	@include margin-bottom(50px);
	@include media('<sm') {
		@include margin-bottom(30px);
	}

	max-width: 500px;

	&__caption {
		@include media('<xs') {
			font-size: 20px;
		}

		font-size: 24px;
		font-weight: bold;
		line-height: 1.25;

		margin-bottom: 20px;
	}

	&__columns {
		display: flex;

		margin: 0 -10px 20px;

		flex-wrap: wrap;
	}

	&__column {
		@include media('<xs') {
			@include margin-bottom(20px);

			width: calc(100% - 20px);
		}

		width: calc(50% - 20px);
		margin: 0 10px;
	}

	&__group {
		@include margin-bottom(20px);
	}

	&__footer {
		margin-top: 20px;
	}
}

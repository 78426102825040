// Fonts
$pfagoraslabpro: 'PFAgoraSlabPro', serif;

// Colors
$teak: #b59068;
$emperor: #545454;
$gray: #939393;
$silverchalice: #a5a5a5;
$tundora: #4a4a4a;
$spicymix: #866542;
$mercury: #e3e3e3;
$yellowmetal: #795b3b;
$milanored: #c20;
$serenade: #fff3e4;

// Transition
$t: .3s ease;

// Breakpoints
$breakpoints: (
	'xs': 568px,
	'sm': 768px,
	'md': 1024px,
	'lg': 1240px
);

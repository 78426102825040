.text {
	@include margin-bottom(1.4286em);

	ul {
		@include reset-list;
		@include margin-bottom(1.4286em);

		& > li {
			@include margin-bottom(4px);

			padding-left: 15px;

			background: url('data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'5\' height=\'5\' viewBox=\'0 0 5 5\'%3E%3Crect fill=\'%23B59068\' width=\'5\' height=\'5\'/%3E%3C/svg%3E%0A') no-repeat 0 6px / 5px 5px;
		}
	}

	table {
		@include margin-bottom(1.4286em);

		width: 100%;

		border-collapse: collapse;

		td {
			padding: 15px 10px;

			border: 0;
		}

		tr:nth-child(2n+1) td {
			background: $serenade;
		}
	}
}

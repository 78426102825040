.footer {
	@include media('<sm') {
		padding: 20px 15px;
	}

	display: flex;

	width: 100%;
	max-width: 1240px;
	margin: 0 auto;
	padding: 20px 30px;

	align-items: center;
	flex-wrap: wrap;
	justify-content: space-between;

	&__copyright {
		@include media('<md') {
			width: 100%;
		}
		@include media('<sm') {
			display: block;
		}

		font-weight: bold;

		display: flex;

		color: $emperor;

		align-items: flex-start;
	}

	&__copyright-hero {
		@include media('<sm') {
			font-size: 16px;
		}

		font-size: 20px;

		text-transform: uppercase;
	}

	&__copyright-info {
		@include media('<sm') {
			width: 100%;
			margin: 5px 0 0;
		}

		margin: 6px 0 0 20px;
	}

	&__counter {
		@include media('<md') {
			width: 100%;
			margin-top: 10px;
		}
	}

	&__counter-image {
		display: block;

		max-width: 100%;
		height: auto;
	}
}

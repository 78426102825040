.s-nav {
	&__list {
		@include reset-list;
	}

	&__item {
		@include margin-top(6px);
	}

	&__link {
		color: $emperor;

		&._active {
			font-weight: bold;

			text-decoration: none;

			color: black;
		}
	}
}

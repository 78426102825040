.consult {
	font-size: 18px;
	font-weight: bold;
	line-height: 1.5;

	display: block;

	width: 251px;
	height: 83px;
	padding-top: 3px;

	text-decoration: none;
	text-transform: uppercase;

	color: black;
	background: url(../images/consult.png) no-repeat 0 0;

	&__line {
		display: block;

		&:nth-child(1) {
			padding-left: 58px;
		}

		&:nth-child(2) {
			padding-left: 75px;
		}

		&:nth-child(3) {
			padding-left: 92px;

			color: $milanored;
		}
	}
}

.services {
	@include margin-bottom(120px);
	@include media('<sm') {
		@include margin-bottom(80px);

		padding: 0 15px;
	}

	max-width: 1240px;
	margin: 0 auto;
	padding: 0 30px;

	&__header {
		display: flex;

		justify-content: center;
	}

	&__suptitle {
		@include media('<sm') {
			font-size: 16px;

			letter-spacing: 0;
		}

		font-size: 18px;
		font-weight: bold;

		letter-spacing: .05em;

		color: $emperor;
	}

	&__title {
		@include media('<sm') {
			font-size: 28px;
		}
		@include media('<xs') {
			font-size: 24px;
		}

		font-size: 48px;
		font-weight: bold;
		line-height: 1;
	}

	&__title-link {
		padding-bottom: 3px;

		text-decoration: none;

		color: black;
		border-bottom: 1px dotted;
	}

	&__list {
		@include media('<sm') {
			margin: 20px -10px 0;
		}

		display: flex;

		margin: 50px -15px 0;

		flex-wrap: wrap;
	}

	&__item {
		@include media('<md') {
			width: calc(50% - 30px);
		}
		@include media('<sm') {
			width: calc(50% - 20px);
			margin: 30px 10px 0;
		}
		@include media('<xs') {
			width: calc(100% - 20px);
		}

		width: calc(25% - 30px);
		margin: 50px 15px 0;

		text-align: center;
	}

	&__hero {
		@include media('<sm') {
			max-width: 100px;
			margin: 0 auto 15px;
		}

		margin-bottom: 20px;
	}

	&__image {
		display: block;

		max-width: 100%;
		height: auto;
		margin: 0 auto;

		border: 3px solid $emperor;
		border-radius: 50%;
	}

	&__caption {
		@include media('<sm') {
			font-size: 16px;
			line-height: 1.25;
		}

		font-size: 20px;
		font-weight: bold;
		line-height: 1.2;

		text-transform: uppercase;

		color: $emperor;
	}

	&__text {
		@include media('<sm') {
			margin-top: 15px;
		}

		margin-top: 25px;
	}
}

.clients {
	@include media('<sm') {
		display: flex;

		margin: -10px -5px 0;

		flex-wrap: wrap;
	}

	&__item {
		@include media('>=sm') {
			@include margin-top(10px);
		}
		@include media('<sm') {
			width: calc(33.3333% - 10px);
			margin: 10px 5px 0;
		}
		@include media('<xs') {
			width: calc(50% - 10px);
			margin: 10px 5px 0;
		}
	}

	&__image {
		display: block;

		max-width: 100%;
		height: auto;

		box-shadow: 1px 1px 0 $mercury, -1px -1px 0 $mercury, 1px -1px 0 $mercury, -1px 1px 0 $mercury;
	}
}

.practice {
	@include margin-bottom(80px);
	@include media('<sm') {
		@include margin-bottom(50px);

		padding: 0 15px;
	}

	max-width: 1240px;
	margin: 0 auto;
	padding: 0 30px;

	&__header {
		display: flex;

		flex-wrap: wrap;
	}

	&__title {
		@include media('<sm') {
			font-size: 18px;
		}

		font-size: 24px;
		font-weight: bold;
		line-height: 1;

		text-transform: uppercase;

		color: $emperor;
	}

	&__list {
		@include reset-list;
		@include media('<md') {
			width: calc(100% + 20px);
			margin: 10px -10px 0;
		}

		display: flex;

		margin: 5px 0 0 20px;

		flex-wrap: wrap;
	}

	&__list-item {
		margin: 0 10px;

		&::before {
			margin-right: .25em;

			content: '\002D';
		}
	}

	&__list-link {
		color: black;
	}

	&__hero {
		@include media('retina2x') {
			background-image: url(../images/practice@2x.png);
		}

		max-width: 1062px;
		margin: 0 auto;
		margin-top: 25px;

		background: url(../images/practice.png) no-repeat 50% 100% / contain;

		&::before {
			display: block;

			padding-top: 14.5952%;

			content: '';
		}
	}

	&__service {
		font-weight: bold;

		position: relative;

		margin-top: 8px;
		padding: 8px 20px;

		text-align: center;

		color: white;
		border-radius: 18px;
		background: $teak;

		&._num_1 {
			@include media('<1182px') {
				margin-left: 35.5329vw;
			}
			@include media('<sm') {
				width: auto;
				margin-left: 0;
			}

			width: 420px;
			margin-left: 420px;
		}

		&._num_2 {
			@include media('<1182px') {
				margin-left: 30.0338vw;
			}
			@include media('<sm') {
				width: auto;
				margin-left: 0;
			}

			width: 250px;
			margin-left: 355px;
		}
	}

	&__connector {
		@include media('<1182px') {
			width: 11.8443vw;
		}
		@include media('<sm') {
			display: none;
		}

		position: absolute;
		bottom: 100%;

		width: 140px;
		height: 8px;

		background: $teak;

		&::before,
		&::after {
			position: absolute;
			top: 0;

			display: block;

			width: 8px;
			height: 8px;

			content: '';

			border-radius: 50%;
			background: white;
		}

		&::before {
			left: -4px;
		}

		&::after {
			right: -4px;
		}

		.practice__service._num_1 & {
			left: 150px;
		}

		.practice__service._num_2 & {
			left: 90px;
		}
	}

	&__note {
		margin-top: 20px;

		text-align: right;

		color: $gray;
	}
}

.overlay {
	position: fixed;
	z-index: 99;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	visibility: hidden;

	transition: opacity .5s ease, visibility .5s ease;
	transform: translate3d(0,0,0);

	opacity: 0;
	background: rgba($teak, .8);

	&._visible {
		visibility: visible;

		opacity: 1;
	}
}

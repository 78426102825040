.crumbs {
	font-size: 14px;
	line-height: 1.4286;

	display: flex;

	margin: -10px -10px 15px;

	flex-wrap: wrap;

	&__item {
		margin: 10px 10px 0;
	}

	&__link {
		position: relative;

		display: block;

		margin-left: 14px;
		padding: 3px 14px 3px 12px;

		color: black;
		background: $serenade;

		&::before {
			@include triangle(26px 14px, $serenade, left);

			position: absolute;
			top: 0;
			right: 100%;

			display: block;

			content: '';
		}
	}
}

.contacts {
	&__title {
		@include media('<sm') {
			font-size: 18px;

			padding: 0 15px;
		}

		font-size: 24px;
		font-weight: bold;
		line-height: 1;

		max-width: 1240px;
		margin: 0 auto;
		padding: 0 30px;

		text-transform: uppercase;

		color: $emperor;
	}

	&__body {
		@include media('<sm') {
			margin-top: 20px;
		}

		position: relative;

		margin-top: 35px;
	}

	&__map {
		@include media('<sm') {
			height: 220px;
		}

		height: 432px;
	}

	&__info {
		@include media('<1280px') {
			left: 50px;
		}
		@include media('<sm') {
			position: static;
			top: auto;
			left: auto;

			width: auto;
			padding: 20px 15px;
		}

		position: absolute;
		top: 0;
		left: calc(50% - 590px);

		width: 460px;
		padding: 30px 20px;

		color: white;
		background: rgba(#a5824b, .87);
	}

	&__text {
		font-size: 14px;
		line-height: 1.2143;

		p {
			@include margin-bottom(1.2143em);

			margin: 0;
		}

		a {
			color: inherit;
		}
	}

	&__callorder {
		margin-top: 25px;
	}
}

.achievements-lite {
	@include media('<sm') {
		display: flex;

		width: auto;
		margin: 0 -10px;
	}

	width: 240px;
	padding-right: 20px;

	&__item {
		@include margin-bottom(20px);
		@include media('<sm') {
			@include margin-bottom(0);

			display: block;

			width: 50%;
			padding: 0 10px;
		}

		display: flex;

		align-items: flex-start;
	}

	&__hero {
		@include media('<sm') {
			font-size: 24px;

			width: auto;

			text-align: center;
		}

		font-size: 48px;
		font-weight: bold;
		line-height: 1;

		display: block;

		width: 86px;

		text-align: right;
	}

	&__note {
		@include media('<sm') {
			width: auto;
			margin-top: 0;
			padding-left: 0;

			text-align: center;
		}

		font-weight: 500;

		display: block;

		width: calc(100% - 86px);
		margin-top: 22px;
		padding-left: 14px;

		color: $emperor;
	}

	&__content {
		@include media('<md') {
			padding: 20px;
		}
		@include media('<sm') {
			width: auto;
			max-width: none;
			margin-top: 15px;
			padding: 20px 15px;
		}

		position: relative;

		width: 470px;
		max-width: calc(100% - 240px);
		margin-top: 30px;
		padding: 35px 40px;

		border-radius: 2px;
		background: white;

		&::before {
			@include triangle(40px 34px, white, up-right);
			@include media('<sm') {
				display: none;
			}

			position: absolute;
			top: 23px;
			right: 100%;

			content: '';
		}
	}

	&__title {
		@include media('<xs') {
			font-size: 20px;
			line-height: 1.25;

			letter-spacing: 0;
		}

		font-size: 30px;
		font-weight: bold;

		letter-spacing: .025em;
	}

	&__text {
		@include media('<xs') {
			font-size: 16px;
			font-weight: normal;
			line-height: 1.25;

			margin-top: 15px;
		}

		font-size: 18px;
		font-weight: bold;
		line-height: 1.2223;

		margin-top: 20px;

		p {
			@include margin-bottom(1.2223em);
			@include media('<xs') {
				@include margin-bottom(1.25em);
			}

			margin: 0;
		}
	}
}

.callorder {
	@include media('<sm') {
		max-width: none;
	}

	max-width: 420px;

	&__btn {
		@include media('<xs') {
			font-size: 14px;
			line-height: 1.4286;

			padding: 12px;
		}

		font-size: 16px;
		font-weight: bold;
		line-height: 1.5;

		display: block;

		width: 100%;
		padding: 15px;

		cursor: pointer;
		transition: background $t;
		text-align: center;

		color: white;
		border: 3px solid white;
		outline: none;
		background: rgba(#be9e7a, 0);

		body:not(.mobile) &:hover {
			background: rgba(#be9e7a, .78);
		}
	}
}
